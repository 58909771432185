import { graphql } from "gatsby"
import { Helmet } from "gatsby-plugin-react-i18next"
import * as React from "react"
import Button from "../components/01_atoms/Button/Button"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import Spacer from "../components/01_atoms/Spacer/Spacer"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import { RenderComponent } from "../components/components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NobleFoodsPage = ({ data }) => {
  const pageData = data.allNodeBasicPage.nodes[0]
  const components = pageData.relationships?.field_components

  return (
    <Layout ui={false}>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />
      <Helmet
        bodyAttributes={{
          class: "noblefoods-page",
        }}
      />

      <ImageTextBlock
        height="100"
        className="hero"
        image={pageData.relationships?.field_image}
        image_position="left"
        slanted
      >
        <Heading size="xxl" spacing="l">
          {pageData.title}
        </Heading>
        <Paragraph>
          <span dangerouslySetInnerHTML={{ __html: pageData.body.processed }} />

          <Spacer height={32} />

          <Button to="/" type="link">
            Go to EMPWR
          </Button>
          <a className="link" href="#block_190" data-link>
            Learn more
          </a>
        </Paragraph>
      </ImageTextBlock>

      {components?.map((component, index) => RenderComponent(component, index))}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default NobleFoodsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeBasicPage(
      filter: {
        status: { eq: true }
        langcode: { eq: "en" }
        field_page_type: { eq: "noblefoods" }
      }
    ) {
      nodes {
        title
        body {
          processed
        }
        field_image {
          drupal_internal__target_id
        }
        path {
          alias
        }
        field_meta_description
        field_meta_title
        relationships {
          field_image {
            ...imageFragment
          }
          field_components {
            ... on Node {
              ...imageTextFragment
              ...productList
              ...standout
              ...videoFragment
            }
          }
        }
        drupal_internal__nid
      }
    }
  }
`
